<template>
  <v-container>
    <v-row align="center" class="row--35">
      <v-col lg="5" md="5" cols="12">
        <div class="thumbnail">
          <slot name="thum-img"></slot>
        </div>
      </v-col>
      <v-col lg="7" md="7" cols="12">
        <div class="about-inner inner">
          <div class="section-title">
            <div class="icon" v-html="iconSvg(icon)"></div>
            <h2 class="heading-title">About the Company.</h2>
            <p class="description">
              We are a multidisciplinary team made up of technicians with more than ten years of experience. We love
              what we do and that is why we cannot avoid being up-to-date in development technologies and new
              methodologies.
            </p>
            <p class="description">
              In our spare time we love to test the latest web development frameworks and continuous
              integration systems. We are obsessed with optimizing work, so that neither we nor you have to do
              repetitive and tedious tasks
              again.
            </p>
            <div class="purchase-btn">
              <router-link class="btn-transparent" to="/"
              >Code Peckers
              </router-link
              >
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import feather from "feather-icons";

export default {
  data() {
    return {
      icon: "send",
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
